import React from 'react';
import '../../style/track-order.scss'
import {Nav} from "../../components";
import Footer from "../../components/footer";

const PrivacyPolicy = ({location}) => {
    return <div className='siteWrapper'>
        <Nav
            location={location}
            title={'Website User Agreement'}
            darkNav={true}
        />

        <div className="pageContainer trackOrder">
            <section className={'info-text'}>
                <h1>Terms of Sale Agreement</h1>

                <p className="color-waterloo">Swaptecs, LLC, and its parent, subsidiaries and affiliates ("Swaptecs,"
                    "we," or "us") offers services (the "Swaptecs Services") through which you can (i) sell certain
                    consumer electronic products (the "Products") after receiving an "offer" from Swaptecs by
                    identifying your Product and its condition and completing check-out on the Swaptecs Websites (each
                    transaction, a "Trade-In") and (ii) buy Products (each sale, a "Purchase"). You must agree to abide
                    by the following terms in order to use the Swaptecs Services and/or the Swaptecs Websites.</p>
                <p className="color-waterloo">BY SETTING UP AN ACCOUNT, OR BY USING SWAPTECS’S WEB SITES, YOU SIGNIFY
                    THAT YOU AGREE TO THE FOLLOWING TERMS AND CONDITIONS, WHICH MAY BE AMENDED AT ANY TIME BY POSTING
                    THE AMENDED TERMS ON THE SWAPTECS WEBSITES. ANY AMENDED TERMS WILL BE AUTOMATICALLY EFFECTIVE
                    IMMEDIATELY AFTER THEY ARE POSTED. Swaptecs reserves the right to terminate or suspend your use of
                    the Swaptecs Websites and/or the Swaptecs Services if you do not comply with this Agreement or any
                    other Swaptecs policy or procedure, or for any other reason we determine, in our sole
                    discretion.</p>

                <ol className={'default'}>
                    <li>About These Terms. These terms govern (i) any offer that you receive to sell your Product to or
                        through Swaptecs’s Website and (ii) any Product you purchase from Swaptecs’s Website. These
                        terms, the terms that govern your use of Swaptecs’s websites, including swaptecs.com and
                        app.swaptecs.com ("Swaptecs Websites"), the Swaptecs Services and any and all applications
                        included therein, which terms are located at www.swaptecs.com/legal, and any supplemental terms
                        or policies that accompany a specific transaction, feature or application, collectively, make up
                        an agreement between you and Swaptecs (the "Agreement"). You acknowledge that this Agreement is
                        in electronic form and has the same force and effect as an agreement in writing. In this
                        Agreement, the term "you" or "your" means an individual or entity exercising rights under this
                        Agreement.
                    </li>

                    <li>Requirements for Trade-In and Purchase. In order to complete a Trade-In or Purchase or to
                        complete any other transaction with Swaptecs, you must: (i) Create an account with us (Trade-In
                        transaction only); (ii) Provide true, up to date and accurate account information about yourself
                        and any Product you submit to us or Purchase from us; (iii) Comply with all terms and conditions
                        of this Agreement; (iv) Comply with all applicable laws and regulations, including all import
                        and export laws as described in Section 15 below; (v) Transact on behalf of yourself and not on
                        behalf of others; and (vi) Be over eighteen (18) years of age and have the legal capacity to
                        enter into agreements and to convey title and interest in any Product that you submit to us.
                    </li>

                    <li>In order to receive payments for any Products in connection with a Trade-In, you must provide us
                        with information including a valid email address at which you are able to receive email, a valid
                        postal address at which you are able to receive mail, and your first and last name. In order to
                        receive any Product you Purchase, you must provide us with information including first and last
                        name, email address at which you receive email, phone number, billing address, and a valid
                        postal address at which you are able to receive mail (if different from the billing address). On
                        Swaptecs’s behalf, PayPal will process your payment for a Purchase and you are required to
                        provide your applicable credit card number, expiration date and CVV code (unless you are paying
                        for the Purchase using your PayPal account). Swaptecs will not receive or store your credit card
                        information.
                    </li>

                    <li>Swaptecs shall not be responsible for communication errors should your contact information be
                        inaccurate or incomplete. You are responsible for ensuring that you can receive emails from
                        Swaptecs and Swaptecs is not responsible for any emails that were not received by you because
                        they were blocked or filtered as spam. You understand and agree that if you fail to provide us
                        with accurate and up to date information about yourself: (a) we will not be responsible for any
                        misapplied Trade-In payments or Trade-In payments sent to a wrong address, and (b) any unclaimed
                        funds may be subject to collection by governmental authorities under applicable unclaimed funds
                        and escheat laws. You agree that we have no obligation to you if any of your unclaimed funds are
                        turned over to governmental authorities in accordance with applicable law.
                    </li>

                    <li>Product Eligibility. Swaptecs determines, in its sole discretion, which Products are eligible
                        for purchase or sale by or through the Swaptecs Services and Swaptecs Websites and for which
                        eligible Products Swaptecs will provide an offer or sell. Swaptecs may terminate the eligibility
                        of certain Products at any time and without advanced notice, but such termination will not
                        affect any Products for which you have already accepted an offer, which is good for thirty (30)
                        days, except as set forth in Section 9 below. Swaptecs reserves the right to cancel any Purchase
                        and refund any applicable purchase price actually received by Swaptecs at any time prior to
                        delivery of the Product to You.
                    </li>

                    <li>Condition Defined for Trade-In and Purchase. Swaptecs uses three (3) basic Product conditions to
                        grade Products for Trade-In, ranging from "Flawless" or "No Noticeable Flaws" to "Broken" or
                        "Functional or physical problems." Conditions can vary widely between categories and types of
                        Products. Broadly, Swaptecs’s Product definitions are as follows: Flawless or No Noticeable
                        flaws: the item looks like it has never been used and is 100% functional. Good or Normal wear
                        and tear: the item has visible signs of use, but is otherwise 100% functional. Broken or
                        Functional or physical problems: the item does not turn on, one or more Product functions is
                        non-operative or the item has serious physical damage that inhibits use (e.g. broken or cracked
                        screen or missing parts). The above definitions are broad guidelines. More specific condition
                        definitions specific to the category and/ or type of Product may be included on the Swaptecs
                        Website offer screen and serve as the effective condition definition for that product. Condition
                        definitions are subject to change at Swaptecs’s discretion. If you are uncertain about your
                        Product's condition, please seek guidance by contacting Swaptecs’s customer care.
                    </li>

                    <li>Product Valuation. All offers will be based on Swaptecs’s own determination of the Product value
                        in reliance on the accuracy of the information you supply us. Swaptecs will use its discretion
                        to determine the Product value, weighing factors that may include the Product's model,
                        functionality and condition and whether necessary Product accessories have been provided by you.
                        Upon receiving an offer, you have the option of accepting or rejecting that offer. Acceptance
                        indicates you are willing to provide your Product to Swaptecs at the offered price. If you do
                        not accept Swaptecs’s offer, the specific transaction expires. If after rejecting the offer you
                        change your mind and want to accept the offer, you will need to open another transaction with us
                        at which point, assuming the Product is still eligible for purchase, the new offer may be
                        different as Product prices are subject to change.
                    </li>

                    <li>Fitness for Sale; Phone and Internet Service. You must have all right, title, and interest in
                        any and all Products you seek to sell to Swaptecs and all Products must be able to be activated
                        for new service (unless the Product is incapable of being activated because it is in "Broken"
                        condition). The Products and the sale and shipment of such items to Swaptecs: (a) must comply
                        with all applicable laws, statutes, ordinances, including without limitation all import and
                        export laws as described in Section 14 below, (b) may not infringe on third party intellectual
                        property rights (including copyrights, trademarks, patents, trade secrets or other proprietary
                        rights), and (c) shall not be counterfeit, stolen, or fraudulent. You represent and warrant that
                        the Products are free of any liens or encumbrances, including third-party software which may not
                        be transferred or for which royalties are due. By using the Swaptecs Services, you agree to
                        indemnify Swaptecs from all claims or losses sustained by Swaptecs as a result of any breach of
                        your representations and warranties. It is your responsibility to discontinue phone, Internet
                        and/or any similar service on your Product prior to selling it to Swaptecs. Swaptecs is not
                        responsible for any service charges related to your Product, whether you incur such charges
                        before, during or after your use of the Services. It is also your responsibility to remove any
                        security codes that would prevent use of any Product.
                    </li>

                    <li>Sending Swaptecs Your Product for Trade-In or Return. When packaging your Product, be sure to
                        include all materials included in your description when we calculated your offer for Trade-In,
                        or, in the case of Purchase, include all materials provided by Swaptecs in the package you
                        received. For Trade-In, this may include software, accessories, adapters, and manuals. Failure
                        to include any items you told us about when the offer was calculated, or sending us a Product
                        which does not match your original description, may impact the final value of your Product and
                        may result in a revised offer.
                    </li>

                    <li>Removing Data From Your Product. For Products involving devices that store files and/or personal
                        data on hard drives, memory chips or the like, you must back up and store any data you wish to
                        keep from your Product and remove any personal information from the Product before you send your
                        Product to us for Trade-In. Likewise for Products you Purchase from Swaptecs and returned to
                        Swaptecs in accordance with these Terms, you agree to remove any such data from the Product
                        prior to returning the Product to Swaptecs. Swaptecs’s standard practice is to remove all such
                        data from the Product; however, Swaptecs makes no guarantee that it will do so. By sending
                        Swaptecs the Product, you agree to release us from any claim as to the Product, the data stored
                        in such Product, or any information on any media used in conjunction with the Product and which
                        you send to Swaptecs (whether in connection with a Trade-In or a Purchase), or for such data's
                        security, integrity, confidentiality, disclosure or use. Swaptecs is not responsible for: (a)
                        any loss suffered by you due to any data that is not erased from the device and transferred to a
                        third party, or (b) any loss of data after you submitted the Product to us.
                    </li>

                    <li>Product Inspection; Offer Recalculation. Your Trade-in Product must be received by Swaptecs
                        within thirty (30) days after you accept the original offer provided by Swaptecs and complete
                        the check-out process on the Swaptecs Website (the "Delivery Period") or the offer shall
                        automatically expire. Packing and shipping recommendations provided to you by Swaptecs should be
                        followed in order to avoid possible damage to or loss of the Product in transit. Swaptecs will
                        inspect all Products that are received. Swaptecs has the option to accept or reject the Product,
                        including without limitation, to reject any Product not conforming to the description you
                        provided to us, any Product modified in such a way that it no longer conforms to the original
                        factory specifications, any Product no longer complying with applicable laws or regulations
                        (e.g., FCC rules, etc.) and/or any Product damaged or lost in transit. If Swaptecs rejects the
                        Product for any of these reasons, the original offer automatically expires and is rescinded.
                        Swaptecs reserves the right to revoke the original offer and provide a revised offer for the
                        Product if: (a) the Product and/or materials are not as described, (b) the Product is received
                        by Swaptecs after the Delivery Period, and/or (c) Swaptecs receives Product(s) that are
                        different from those identified when your offer was calculated. As described above, Swaptecs
                        typically honors the offer price you receive for your Product on the Site for a period of thirty
                        (30 days); sometimes for a longer period of time in connection with a temporary extended
                        price-lock offer (each a "Price-Lock Offer"). Notwithstanding any Price-Lock Offer, Swaptecs
                        always reserves the right to recalculate the original offer price and provide a revised offer
                        for the Product if, during the Price-Lock Offer period, the market price for your Product has
                        declined by more than thirty percent. Swaptecs shall determine the market price during such
                        applicable period and whether there has been any such decline in its sole discretion. If you
                        elect not to accept the recalculated offer price, your Product will be returned to you.
                    </li>

                    <li>Recalculated Product Offers after Inspection. In the event Swaptecs recalculates the offer it
                        provided for the Product after it has been received and inspected for Trade-In, as described
                        above, you shall have the option of accepting or rejecting the new offer. If you accept the new
                        offer, you will be paid in normal course and in accordance with these service terms. If you
                        reject the new offer, Swaptecs will return the Product to you at the address from which it was
                        originally sent. Swaptecs will give you a period of five (5) days after Swaptecs presents you
                        with a recalculated offer via email at the address you provided to accept or reject the new
                        offer (the "New Offer Period"). Swaptecs will also remind you of the outstanding new offer one
                        or more times during the New Offer Period. However, if the new offer is neither affirmatively
                        accepted nor rejected by you during the New Offer Period, the new offer will be deemed to have
                        been accepted by you and you will be paid the new offer price in the normal course and in
                        accordance with these service terms. For the avoidance of doubt, your acceptance of the original
                        offer and/or any new offer is final and you may not change your mind later about accepting such
                        offer.
                    </li>

                    <li>Releasing Rights to Product. Once you send Swaptecs your Product for Trade-In, Swaptecs cannot
                        and does not guarantee that it will be able to honor any request for return of the Product
                        and/or any data or information contained in such Product. Of course, if Swaptecs rejects the
                        Product or you do not accept any new offer for the Product pursuant to Sections 9 and 10
                        ("Return Circumstances"), your Product will be returned to you as set forth in Sections 9 and
                        10.
                    </li>

                    <li>Risk-Of-Loss. You remain responsible for the risk-of-loss for the Products sent to Swaptecs for
                        Trade-In until delivery of the same to Swaptecs. You are responsible for any damages that may
                        occur to your Product while in transit. Swaptecs is responsible for risk-of-loss when it opens
                        the packaging containing your Product and ceases in the event Swaptecs returns your product to
                        you for any reason. For the avoidance of doubt, in such event, risk of loss will be transferred
                        to you once Swaptecs delivers the package to the carrier for return to you and you will bear the
                        risk-of-loss while the Product is in transit.
                    </li>

                    <li>If you return a Product to Swaptecs that you Purchased, you are responsible for the risk-of-loss
                        for the Product until Swaptecs receives the Product. You are responsible for any damages that
                        may occur to the Product while in transit back to Swaptecs.
                    </li>

                    <li>Passing of Title. Title to the applicable Product sent to Swaptecs for Trade-In passes to
                        Swaptecs upon Swaptecs’s acceptance of the Product for the original offer or the new offer, as
                        applicable. If there is a Return Circumstance, title shall not pass and shall remain with you.
                    </li>

                    <li>Non-Transferable. Any offer by Swaptecs for your Product for Trade-In is non- transferable and
                        not redeemable for any other consideration other than what is offered by Swaptecs.
                    </li>

                    <li>Import and Export Laws. You agree to comply fully with all import and export laws, regulations,
                        rules and orders of the United States, or any foreign government agency or authority, and that
                        you will not directly or indirectly transfer any Product without proper authorization from the
                        U.S. and/or foreign government. You bear responsibility for and assume all expenses relating to
                        your compliance with the described laws, regulations, rules and orders, and for obtaining all
                        necessary authorizations and clearances in order to convey any Product to Swaptecs in connection
                        with a Trade-In, including without limitation, valuation, classification and duties applicable
                        with the import of any goods. Please note: U.S. trade sanctions administered by the Office of
                        Foreign Assets Control (OFAC) generally prohibit the importation into the United States
                        (including U.S. territories), either directly or indirectly, of most goods, technology, or
                        services (except information and informational materials) from, or which originated from Cuba,
                        Iran, Iraq, Libya, North Korea, Serbia, or Sudan; from foreign persons designated by the
                        Secretary of State as having promoted the proliferation of weapons of mass destruction; named
                        Foreign Terrorist Organizations; designated terrorists and narcotics traffickers. You may not
                        offer or provide to Swaptecs for sale, any Products that would violate the provisions of this
                        Section 15.
                    </li>

                    <li>Promotions. From time to time, and at Swaptecs’s sole discretion, Swaptecs may offer promotional
                        programs as an incentive to customers. Promotions applicable to payments are applied as an
                        increment to the final value of a customer's Product, but they are not considered a change in
                        that final value. Promotional programs have explicitly defined terms, including but not limited
                        to, expiration, usage per person and per transaction, and other limitations and restrictions.
                        Unless otherwise stated, promotions must be applied by you before checkout. For the avoidance of
                        doubt, once checkout is completed, promotions cannot be applied to the same transaction.
                        Swaptecs reserves the right, in its sole discretion, to cancel or refuse promotions.
                    </li>

                    <li>Accuracy of all Information. Swaptecs makes every effort to ensure the accuracy of all
                        information you receive in relation to your Product. From time to time, however, there may be
                        typographical errors, technical inaccuracies, pricing or other errors or omissions. As such,
                        Swaptecs reserves the right, at any time prior to payment, to:(a) Correct an error. (b) Change
                        the offer or cancel the Purchase prior to your receipt of the Product. In the event the Product
                        has already been sent to Swaptecs for Trade-In, the changed offer will become a New Offer
                        subject to the processes set forth in Section 9 and Section 10. Otherwise, the changed offer
                        will be a re-issued original offer, which you may accept or reject. (c) Void a transaction. If
                        the Product was already sent to Swaptecs, Swaptecs will be deemed to have rejected the Product
                        resulting in a Return Circumstance subject to Section 11.
                    </li>

                    <li>Privacy. Please refer to our Privacy Policy for information about how we collect, use and
                        disclose information about you.
                    </li>

                    <li>Electronic Notices and Transactions. You agree to transact with us electronically, including
                        without limitation, agreeing to terms and conditions or offering to sell your Product by
                        electronic means. You authorize Swaptecs to provide you with terms and important notices about
                        Swaptecs and your transactions to an email address you provide to us or by posting notices on an
                        applicable page (or my account area) on www.swaptecs.com. It is your duty to keep your email
                        address accurate and up to date and to maintain a valid email address and to ensure that emails
                        we send you are not filtered or stopped by spam filters or other types of email blocking
                        functionalities. If you no longer desire to transact electronically with us, you may no longer
                        use the Swaptecs Websites. You can retrieve and review this Agreement by clicking on the "Legal"
                        link at www.swaptecs.com/.
                    </li>

                    <li>Access. You are responsible for obtaining at your own expense all equipment and services needed
                        to access and use the Swaptecs Websites and the Swaptecs Services, including all devices,
                        Internet browsers and Internet access. If you access the Swaptecs Website and the Swaptecs
                        Services through a mobile or wireless device, you are responsible for all fees that your carrier
                        may charge you for data, text messaging and other wireless access or communications services.
                    </li>

                    <li>Use of Services. By using Swaptecs Websites, you agree not to (i) access any of the Services by
                        any means (including, without limitation, by use of scripts, web crawlers or similar methods)
                        other than through the user interface provided by Swaptecs; and (ii) engage in any other
                        activity that interferes with or disrupts the Services or performance of the Swaptecs Websites.
                    </li>

                    <li>No Warranties. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE PROVIDE THE SWAPTECS
                        SERVICES AND WEBSITES "AS IS," "WITH ALL FAULTS," AND "AS AVAILABLE," WITHOUT EXPRESS OR IMPLIED
                        WARRANTIES OF ANY KIND. WE DISCLAIM ANY IMPLIED WARRANTIES OF MERCHANTABILITY, ACCURACY, FITNESS
                        FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT AND THE IMPLIED CONDITIONS OF SATISFACTORY QUALITY.
                        WE MAKE NO REPRESENTATIONS THAT YOUR TRANSACTIONS WITH SWAPTECS WILL MEET YOUR REQUIREMENTS.
                    </li>

                    <li>Limited Liability. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL SWAPTECS
                        OR ITS DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, DISTRIBUTORS OR CONTRACTORS (COLLECTIVELY, THE
                        "SWAPTECS PARTIES") BE LIABLE FOR ANY COMPENSATORY, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR
                        SPECIAL DAMAGES, LOSS OF DATA, INCOME OR PROFIT, LOSS OF OR DAMAGE TO PROPERTY, OR ANY CLAIMS OF
                        YOU OR OTHER THIRD PARTIES WHATSOEVER WITH RESPECT TO YOUR TRANSACTIONS WITH SWAPTECS UNDER THIS
                        AGREEMENT REGARDLESS OF THE LEGAL THEORY ON WHICH THE CLAIM IS BASED, WHETHER BASED IN CONTRACT,
                        TORT, NEGLIGENCE, WARRANTY, OR OTHERWISE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
                        SUCH DAMAGES. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE MAXIMUM
                        AGGREGATE LIABILITY OF SWAPTECS AND THE SWAPTECS PARTIES (JOINTLY) ARISING OUT OF OR RELATING TO
                        YOUR ACCESS TO OR USE OF THE SWAPTECS SERVICES AND SWAPTECS WEBSITES EXCEED THE GREATER OF (I)
                        THE AMOUNT PAID, IF ANY, BY YOU TO US OR (II) $100. THE LIMITATIONS SET FORTH IN THIS AGREEMENT
                        WILL NOT LIMIT OR EXCLUDE LIABILITY FOR OUR GROSS NEGLIGENCE, FRAUD OR INTENTIONAL, MALICIOUS OR
                        RECKLESS MISCONDUCT, OR FOR PERSONAL INJURY OR PROPERTY DAMAGE CAUSED BY PRODUCTS YOU PURCHASE
                        THROUGH THE SWAPTECS SERVICES.
                    </li>

                    <li>Indemnity. You agree to indemnify, defend, and hold harmless Swaptecs and the Swaptecs Parties
                        from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees
                        (including reasonable attorneys' fees) that such parties may incur as a result of or arising
                        from your violation or breach of any terms under this Agreement. We reserve the right to assume
                        the exclusive defense and control of any matter otherwise subject to indemnification by you and,
                        in such case, you agree to cooperate with our defense of such claim.
                    </li>

                    <li>Entire Agreement/No Waiver. This Agreement constitutes the entire agreement of the parties with
                        respect to the subject matter hereof. The captions are used only as a matter of convenience and
                        are not to be considered a part of this agreement or be used in determining the intent of the
                        parties to it. No waiver by Swaptecs of any breach or default hereunder shall be deemed to be a
                        waiver of any preceding or subsequent breach or default. Sections 6, 8, 11, 13, 15, 18, 19 and
                        22 through 28 shall survive termination of this Agreement.
                    </li>

                    <li>Correction of Errors and Inaccuracies. The Swaptecs Websites and any correspondence related to a
                        transaction may contain typographical errors or other errors or inaccuracies and may not be
                        complete or current. Swaptecs therefore reserves the right to correct any errors, inaccuracies
                        or omissions and to change or update the Content at any time without prior notice. Swaptecs does
                        not, however, guarantee that any errors, inaccuracies, or omissions will be corrected.
                    </li>

                    <li>Modification. Swaptecs reserves the right to modify this Agreement at any time. If you do not
                        agree to the changes, you may discontinue using the Swaptecs Websites and/or the Swaptecs
                        Services. Your continued use of any Swaptecs Website and/or Swaptecs Services after any such
                        changes take effect constitutes your acceptance to such changes. Each time you submit a Product
                        for sale to Swaptecs, you reaffirm your acceptance of this Agreement as in effect at the time of
                        such use. You are responsible for reviewing this Agreement each time you use the Swaptecs
                        Websites and/or the Swaptecs Websites. The Agreement is and will be located at
                        www.swaptecs.com/service_terms. The last date this Agreement was revised is set forth below.
                    </li>

                    <li>Applicable Law and Venue. THIS AGREEMENT AND THE TERMS OF SALE AND TRANSFER OF TITLE OF YOUR
                        PRODUCT ARE GOVERNED BY AND CONSTRUED IN ACCORDANCE WITH MASSACHUSETTS LAW AND TO THE EXTENT
                        APPLICABLE, THE LAWS OF THE UNITED STATES. NO CONFLICT OF LAWS OR PROVISIONS OF ANY JURISDICTION
                        WILL APPLY TO THESE TERMS AND CONDITIONS. BY CLICKING ON THE "ACCEPT" BUTTON BELOW, YOU AGREE
                        THAT ANY ACTION AT LAW OR INEQUITY ARISING OUT OF OR RELATING TO THESE TERMS AND CONDITIONS WILL
                        BE FILED ONLY IN STATE OR FEDERAL COURT LOCATED IN SUFFOLK COUNTY, MASSACHUSETTS, AND YOU HEREBY
                        IRREVOCABLY AND UNCONDITIONALLY CONSENT AND SUBMIT TO THE EXCLUSIVE JURISDICTION OF SUCH COURTS
                        OVER ANY SUIT, ACTION, OR PROCEEDING ARISING OUT OF THESE TERMS AND CONDITIONS. ALL DISPUTES
                        MUST BE ADJUDICATED IN THE ENGLISH LANGUAGE.
                    </li>
                </ol>
                <p>
                    If you have any questions, concerns, or suggestions regarding the above Agreement, please feel free
                    to email support@swaptecs.com.
                </p>
                <p>Last Updated: August 21, 2020</p>
            </section>

            <section className={'info-text'}>
                <h2>Website User Agreement</h2>

                <p>Welcome! You may use the website, services, content, technologies and applications of swaptecs.com
                    (the "Website") only on the condition that you abide by the following terms.</p>
                <p>BY USING THE WEBSITE AND/OR BY REGISTERING TO OPEN AN ACCOUNT WITH US, YOU SIGNIFY YOUR AGREEMENT TO
                    THE FOLLOWING TERMS ("SITE TERMS").</p>

                <h3>1. About The Site Terms</h3>
                The Website is a service made available by Swaptecs, LLC (doing business as Swaptecs) and its parent,
                subsidiaries and affiliates ("Swaptecs," "we" or "us"). The Site Terms have the same effect as an
                agreement in writing and govern your use of the Website. If you do not agree to the Site Terms, please
                do not use the Website. We may modify the Site Terms at any time. If you do not agree to the changes,
                you may discontinue using Swaptecs before the changes take effect. Your continued use of Swaptecs after
                any such changes take effect constitutes your acceptance to such changes. Each time you visit or log
                into the Website, you reaffirm your acceptance of the Site Terms. You are responsible for regularly
                reviewing the Site Terms, by clicking on the "Legal" link at www.swaptecs.com. The Site Terms may be
                supplemented by additional terms and conditions applicable to specific areas of this Website, or to
                particular content or transactions are posted in particular areas of the site, which, together with
                these Site Terms, govern your use of those areas, content, or transactions.

                <h3>2. About the Website</h3>
                The Website gives users the ability to sell or donate certain electronic devices such as mobile phones
                (collectively, the "Product") subject to these Site Terms. You agree that all transactions will be
                performed electronically and that the terms of any offer to sell or donate any Product will be governed
                by Swaptecs’s Terms of Sale Agreement located at https://www.swaptecs.com/service_terms. We may, in our
                sole discretion, change or discontinue any or all aspects of the Website at any time, without notice,
                and without liability to you.

                <h3>3. Registration</h3>
                Registration is not required to visit the Website; however, you will need to register with us and create
                an account if you desire to use all of the Website's services and features or to complete a transaction
                with us. If you register to open an account, you agree to provide accurate, complete registration
                information. You must be legally capable to enter into contracts. It is your responsibility to make any
                updates to that information. Each registration is for a single person only, unless specifically
                designated otherwise on the registration page. We do not permit any other person using the registered
                sections under your name and you agree not to share your account credentials with others. If you believe
                there has been unauthorized use of your account, notify Swaptecs immediately by emailing
                support@swaptecs.com.

                <h3>4. Ownership</h3>
                The Website contains information, content or advertisements text, photographs, designs, graphics,
                images, sound and video recordings, animation and other materials and effects (collectively, the
                "Content") that are protected by copyrights, trademarks, service marks, trade dress, patents or other
                intellectual or proprietary rights owned by Swaptecs or other third parties. All trademarks and
                copyrighted information contained on the Website are the property of their respective owners. Further,
                Swaptecs retains all rights (including intellectual property rights), title and interest in the Website,
                technology, and all underlying technology and data including any enhancements, software, applications
                and improvements related to the Website (the "Technology")(the terms Content and Technology collectively
                will be referred to as the "Materials"). You may not remove from any electronic or printed copy any
                copyright, trademark, or other proprietary notice.

                <h3>5. Use of Website and Materials</h3>
                <p>Swaptecs provides the Website and the Materials for your individual, non-commercial use and solely
                    for the purpose of carrying out individual transactions with the Website. Any other use of the
                    Website or the Materials is strictly prohibited. You may not, either directly or indirectly:</p>
                <ul className={'default'}>
                    <li>Modify, republish, redistribute, delete, resell, sublicense, publicly perform, cache by proxy
                        the Website or Materials without the express written permission of Swaptecs or the applicable
                        rights holder;
                    </li>
                    <li>Use the Materials for telemarketing, direct marketing, and commercial mass e-mail or by agents
                        or representatives or e-mail spammers;
                    </li>
                    <li>Reverse engineer, decompile, disassemble, merge, copy, use, disclose, rent, lease, loan, sell,
                        sublicense or transfer the underlying source code or structure or sequence of the Technology or
                        delete or alter author attributes or copyright notices;
                    </li>
                    <li>Use any network monitoring or discovery software to determine the site architecture, or extract
                        information about usage or users;
                    </li>
                    <li>Reformat or frame any portion of the Website or Materials;</li>
                    <li>Use any device, software or routine that interferes with the proper working of the Website, or
                        otherwise attempt to interfere with the proper working of the Website;
                    </li>
                    <li>Allow others to use the Website under or through your account login ID/email and password;</li>
                    <li>Take any action that imposes, or may impose in our sole discretion an unreasonable or
                        disproportionately large load on our infrastructure;
                    </li>
                    <li>Violate the Site Terms, applicable law or the rights of others; or Disrupt or interfere with the
                        security of, or otherwise cause harm to, the Website.
                    </li>
                    <li>Any permitted use of the Website does not extend to using the Website or Materials for any
                        illegal purpose, or to transmit to or through the Website or to or through any service any
                        illegal, harmful, threatening, defamatory, obscene, hateful, pornographic or other objectionable
                        material of any kind, or to interfere with, abuse or otherwise violate the legal rights of any
                        third party using the Website or Materials.
                    </li>
                    <li>Without limiting other remedies, we may limit, suspend, or terminate our service and user
                        accounts, prohibit access to our website, remove hosted content, and take technical and legal
                        steps to keep users off the Sites if we think that they are creating problems, possible legal
                        liabilities, or acting inconsistently with the letter or spirit of our policies. We also reserve
                        the right to cancel unconfirmed accounts.
                    </li>
                </ul>

                <h3>6. License</h3>
                <p>By using the Website, uploading content to or submitting any materials for use on the Website, you
                    grant (or warrant that the owner of such rights has expressly granted Swaptecs a perpetual,
                    royalty-free, irrevocable, non-exclusive right and license to use, reproduce, modify, adapt,
                    publish, translate, create derivative works from and distribute such materials and/or information
                    generated through use of the Website or incorporate such materials and/or information generated
                    through use of the Website into any form, medium, or technology now known or later developed
                    throughout the universe. We need these rights to host and display your content.</p>

                <h3>7. Automated Activity</h3>
                <p>The Website may use robot exclusion methods, which include robots.txt files and HTML meta tags, that
                    expressly allow and/or exclude specified automated programs from accessing certain portions of the
                    Website. Much of the information on the Website is updated on a real time basis and is proprietary
                    or is licensed to Swaptecs by our users or third parties. You agree that you will not use any robot,
                    spider, scraper or other automated means to access the Website for any purpose, including but not
                    limited to performing "offline" searches and mirroring, without our express written permission as
                    indicated in the then current robots.txt file or HTML meta tags on the Website. Additionally, you
                    agree that you will not bypass our robot exclusion methods or other measures we may use to prevent
                    or restrict access to the Website.</p>

                <h3>8. Linking</h3>
                <p>You may provide links only to the homepage of this Website, provided (a) you do not remove or
                    obscure, by framing or otherwise, any portion of the homepage, (b) you give Swaptecs notice of such
                    link by sending an e-mail to support@swaptecs.com and (c) you discontinue providing links to this
                    Website if requested by Swaptecs. If you wish to provide links to a section within the Website, you
                    should forward your request to Swaptecs at support@swaptecs.com and Swaptecs will notify you if,
                    within its sole and unfettered discretion, permission is granted, and, if so, the terms and
                    conditions of the permission.</p>

                <h3>9. Representations</h3>
                <p>You represent and warrant to us that (a) you are legally capable to enter into contracts, (b) you are
                    providing us at all times true, accurate and up to date information about yourself, (c) you will
                    comply at all times with the Site Terms and applicable law and (d) your use of the Website and any
                    transactions that you make with us will not violate the rights of any third party. You further
                    represent and warrant that the device you intend to sell is rightfully owned by you free and clear
                    of all liens and/or encumbrances.</p>

                <h3>10. Disclaimer</h3>
                <p>Swaptecs makes every effort to ensure the information presented in, on or through its Website is
                    accurate, however, because Swaptecs communicates information provided and created by advertisers,
                    software developers, publishers, marketing agents, resellers and other third parties, Swaptecs has
                    no control over the accuracy of such information, and makes no guarantee as to such information, and
                    is not responsible for the information, including its accuracy, currency, content, quality,
                    copyright compliance or legality, or any resulting loss or damage.</p>

                <h3>11. Warranty and Liability</h3>
                <p>Swaptecs makes no representations regarding the availability and performance of its Website. You
                    hereby acknowledge that any use of the Website and reliance upon any Materials shall be at your sole
                    risk.</p>
                <p>THE WEBSITE AND MATERIALS ARE PROVIDED BY SWAPTECS ON AN "AS IS" BASIS AND AS AVAILABLE, WITHOUT ANY
                    WARRANTY OR REPRESENTATION OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE. TO THE
                    FULLEST EXTENT PERMITTED BY APPLICABLE LAW, SWAPTECS EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES,
                    EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY, ACCURACY, FITNESS
                    FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT AND THE IMPLIED CONDITIONS OF SATISFACTORY QUALITY AND
                    ACCEPTANCE AS WELL AS ANY LOCAL JURISDICTIONAL ANALOGUES TO THE ABOVE AND OTHER IMPLIED OR STATUTORY
                    WARRANTIES. WE MAKE NO REPRESENTATIONS THAT THE WEBSITE OR MATERIALS WILL MEET YOUR REQUIREMENTS AND
                    WE DO NOT GUARANTEE THAT ANY CONTENT, INFORMATION, SOFTWARE OR OTHER MATERIAL ACCESSIBLE THROUGH THE
                    WEBSITE WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>
                <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL SWAPTECS OR ITS DIRECTORS,
                    OFFICERS, EMPLOYEES, AFFILIATES, AGENTS, DISTRIBUTORS OR CONTRACTORS (COLLECTIVELY, THE "SWAPTECS
                    PARTIES") BE LIABLE FOR ANY COMPENSATORY, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR SPECIAL DAMAGES,
                    LOSS OF DATA, INCOME OR PROFIT, LOSS OF OR DAMAGE TO PROPERTY, OR ANY CLAIMS OF YOU OR OTHER THIRD
                    PARTIES WHATSOEVER WITH RESPECT TO THE WEBSITE OR THE MATERIALS REGARDLESS OF THE LEGAL THEORY ON
                    WHICH THE CLAIM IS BASED, WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, WARRANTY, OR OTHERWISE, EVEN
                    IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE FULLEST EXTENT PERMITTED BY
                    APPLICABLE LAW, SWAPTECS AND THE SWAPTECS PARTIES SHALL NOT BE LIABLE FOR ANY LOSSES OR DAMAGES
                    WHATSOEVER RESULTING FROM ANY EVENTS BEYOND OUR REASONABLE CONTROL, INCLUDING BUT NOT LIMITED TO,
                    FAILURE OF THE INTERNET OR ANY VIRUS, DELAY OR INTERRUPTION IN OPERATION OR SERVICE OR FAILURE OF
                    PERFORMANCE, WHETHER OR NOT RESULTING FROM AN ACT OF GOD, COMMUNICATIONS FAILURE, THEFT OR
                    OTHERWISE. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE MAXIMUM
                    AGGREGATE LIABILITY OF SWAPTECS AND THE SWAPTECS PARTIES (JOINTLY) ARISING OUT OF OR RELATING TO
                    THESE SITE TERMS OR YOUR USE OF THE WEBSITE EXCEED THE GREATER OF (I) THE AMOUNT PAID, IF ANY, BY
                    YOU TO US OR (II) $100. THE LIMITATIONS SET FORTH IN THESE SITE TERMS WILL NOT LIMIT OR EXCLUDE
                    LIABILITY FOR OUR GROSS NEGLIGENCE, FRAUD, OR INTENTIONAL, MALICIOUS OR RECKLESS MISCONDUCT, OR FOR
                    PERSONAL INJURY OR PROPERTY DAMAGE CAUSED BY PRODUCTS YOU PURCHASE THROUGH THE WEBSITE.</p>

                <h3>12. Indemnity</h3>
                <p>You agree to indemnify, defend, and hold harmless Swaptecs and the Swaptecs Parties from and against
                    any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable
                    attorneys' fees) that such parties may incur as a result of or arising from your violation or breach
                    of any representation or obligation under these Site Terms. We reserve the right to assume the
                    exclusive defense and control of any matter otherwise subject to indemnification by you and, in such
                    case, you agree to cooperate with our defense of such claim.</p>

                <h3>13. Privacy</h3>
                <p>Please refer to our Privacy Policy for information about how we collect, use and disclose information
                    about you.</p>

                <h3>14. Electronic Notices and Transactions</h3>
                <p>You agree to transact with us electronically. This means you agree to accept and terms and conditions
                    and to transact any sale, donation or recycling of your Product with us by electronic means. You
                    authorize us to send you important notices about the Website and any pending transactions to an
                    email address you provide to us, if you are a registered account holder or, in the alternative, by
                    posting a notice on the Website. It is your duty to keep your email address up to date and to
                    maintain a valid email address and to ensure that emails we send you are not filtered or stopped by
                    spam filters or other types of email blocking functionalities. If you no longer desire to transact
                    electronically with us, you may no longer use the Website. You can retrieve and review these Site
                    Terms at any time by visiting https://www.swaptecs.com/legal.</p>

                <h3>15. Access</h3>
                <p>You are responsible for obtaining at your own expense all equipment and services needed to access and
                    use the Website, including all devices, Internet browsers and Internet access. If you access the
                    Website or a Website application through a mobile or wireless device, you are responsible for all
                    fees that your carrier may charge you for data, text messaging and other wireless access or
                    communications services.</p>

                <h3>16. Notice Regarding Technology</h3>
                <p>Our Technology, including any software or applications we provide to you, is a "commercial item," as
                    that term is defined in 48 C.F.R. 2.101, consisting of "commercial computer software" and
                    "commercial computer software documentation," as such terms are used in 48 C.F.R. 12.212. Consistent
                    with 48 C.F.R. 12.212 and 48 C.F.R. 227.7202-1 through 227.7202-4, all U.S. Government end users
                    acquire the software with only those rights set forth herein.</p>
                <p>Your use of the Website must comply with all applicable laws in the territory in which you access and
                    use the Website, including without limitation, all import and export control laws and regulations of
                    the United States and other countries. You must not transfer, by electronic transmission or
                    otherwise, any content, data, or software subject to restrictions under such laws to a national
                    destination, person, or entity or for an end-use prohibited under those laws without first obtaining
                    and complying with government authorization. You must not upload any data or software to the Website
                    that cannot be exported without prior written government authorization and notification, including,
                    but not limited to, certain types of encryption software.</p>

                <h3>17. Miscellaneous</h3>
                <p>Swaptecs reserves the right to investigate complaints or reported violations of the Site Terms and to
                    take any action Swaptecs deems appropriate including but not limited to reporting any suspected
                    unlawful activity to law enforcement officials, regulators, or other third parties and disclosing
                    any information necessary or appropriate to such persons or entities relating to user profiles,
                    e-mail addresses, usage history, posted materials, IP addresses and traffic information. Swaptecs
                    reserves the right to seek all remedies available at law and in equity for violations of these Site
                    Terms, including but not limited to the right to block access from a particular Internet address to
                    any Swaptecs website. These Site Terms incorporate by reference any agreements contained on the
                    Website and constitutes the entire agreement with respect to access to and use of the Website. If
                    any provision of these Site Terms is unlawful, void or unenforceable then that provision shall be
                    deemed severable from the remaining provisions and shall not affect their validity and
                    enforceability. THESE SITE TERMS SHALL BE GOVERNED BY AND CONSTRUED IN ACCORDANCE WITH THE LAWS OF
                    THE COMMONWEALTH OF MASSACHUSETTS, U.S.A., WITHOUT REGARD TO CONFLICT OF LAW RULES OR PRINCIPLES
                    THAT WOULD CAUSE THE APPLICATION OF LAWS OF ANY OTHER JURISDICTION. YOU IRREVOCABLY CONSENT AND
                    WAIVE ALL OBJECTION TO PERSONAL JURISDICTION AND VENUE IN THE STATE AND FEDERAL COURTS LOCATED IN
                    SUFFOLK COUNTY, MASSACHUSETTS, USA, AND YOU SHALL NOT COMMENCE OR PROSECUTE ANY SUIT OR ACTION
                    EXCEPT IN THE FOREGOING COURTS. The obligations in Sections 5 through 8, 10 through 15, 17 and 18
                    shall survive termination of these Site Terms.</p>
                <p>Last Updated: May 9, 2021</p>
            </section>
        </div>

        <Footer/>
    </div>
}

export default PrivacyPolicy;